@import "./lib/common";

$content-width: 1080px;
$content-inner-width: 800px;
$c-red: #931B2F;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

a {
  color: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
}


// common ----------------------------------
.ribbon {
  background: url("./imgs/ribbon.png") repeat-x center / contain;
  width: 100%;

  @include pc {
    height: 25px;
  }
  @include sp {
    height: 10px;
  }
}

.ribbon-white {
  background: url("./imgs/ribbon-white.png") repeat-x center / contain;
  width: 100%;

  @include pc {
    height: 25px;
  }
  @include sp {
    height: 10px;
  }
}

// wapper -----------------------------------
.container {
  @include pc {
    min-width: 1080px;
  }
}

// mv ---------------------------------------

.mv {
  @include pc {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
  }

  @include sp {
    width: 100%;
    height: 420px;
  }
}

.mv-ribbon {
  background: url("./imgs/ribbon.png") repeat-x center / contain;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 10;

  @include pc {
    height: 20px;
    top: 27px;

  }
  @include sp {
    height: 10px;
    top: 16px;

  }
}

.swiper {
  @include sp {
    height: 420px;
  }
}

.swiper-slide {

  @include pc {
    padding: 50.9% 0 0;
    &[data-slide1] {
      background: url("./imgs/slide1@2x.jpg") center / cover;
    }
    &[data-slide2] {
      background: url("./imgs/slide2@2x.jpg") center / cover;
    }
    &[data-slide3] {
      background: url("./imgs/slide3@2x.jpg") center / cover;
    }
    &[data-slide4] {
      background: url("./imgs/slide4@2x.jpg") center / cover;
    }
    &[data-slide5] {
      background: url("./imgs/slide5@2x.jpg") center / cover;
    }
    &[data-slide6] {
      background: url("./imgs/slide6@2x.jpg") center / cover;
    }
    &[data-slide7] {
      background: url("./imgs/slide7@2x.jpg") center / cover;
    }
  }
  @include sp {
    height: 420px;

    &[data-slide1] {
      background: url("./imgs/sp/slide1@2x.jpg") center / cover;
    }
    &[data-slide2] {
      background: url("./imgs/sp/slide2@2x.jpg") center / cover;
    }
    &[data-slide3] {
      background: url("./imgs/sp/slide3@2x.jpg") center / cover;
    }
    &[data-slide4] {
      background: url("./imgs/sp/slide4@2x.jpg") center / cover;
    }
    &[data-slide5] {
      background: url("./imgs/sp/slide5@2x.jpg") center / cover;
    }
    &[data-slide6] {
      background: url("./imgs/sp/slide6@2x.jpg") center / cover;
    }

    &[data-slide7] {
      background: url("./imgs/sp/slide7@2x.jpg") center / cover;
    }
  }
}


// top ---------------------------------------
.top {
  max-width: $content-width;
  @include pc {
    margin: 75px auto 0;
  }
  @include sp {
    margin: 36px auto 0;
  }
}

.top-body {
  @include pc {
    display: flex;
    justify-content: space-between;
  }
}

.top-logo {
  flex: 0 0 auto;
  @include pc {
    @include img("./imgs/logo@2x.png", 403px, 194px);
  }
  @include sp {
    @include img("./imgs/logo.png", 310px, 146px);
    margin: 0 auto;
  }
}

.top-text {
  @include pc {
    @include img("./imgs/top-text@2x.png", 528px, 250px);
  }
  @include sp {
    @include img("./imgs/sp/top-text@2x.png", 0, 0);
    max-width: 330px;
    width: 100%;
    margin: 30px auto 0;
    padding: 42% 0 0;
  }
}

.top-button {

  @include pc {
    @include img("./imgs/top-button@2x.png", 440px, 80px);
    margin: 30px auto 0;
    transition: opacity 200ms;

    &:hover {
      opacity: 0.7;
    }
  }

  @include sp {
    @include img("./imgs/top-button.png", 260px, 55px);
    margin: 34px auto 0;
  }
}


// about ---------------------------------------

.about {
  @include pc {
    margin: 70px 0 0;
  }
  @include sp {
    margin: 40px 0 0;
  }
}

.about-inner {
  @include pc {
    max-width: $content-width;
    margin: 110px auto 0;
    display: flex;
  }
}

.about-title {
  @include pc {
    @include img("./imgs/about-title@2x.png", 504px, 82px);
  }

  @include sp {
    @include img("./imgs/sp/about-title@2x.png", 334px, 51px);
    background-position: left center;
    background-size: auto 100%;
    margin: 32px 30px 0;
    width: calc(100% - 60px);
  }
}

.about-content1 {
  @include pc {
    flex: 0 0 auto;
    width: 500px;
    margin: 0 80px 0 0;
  }
}

.about-text1 {
  color: $c-red;
  line-height: 1.85;
  font-weight: bold;
  @include pc {
    margin: 30px 0 0;
    font-size: 19px;

  }

  @include sp {
    margin: 20px 30px 0;
    font-size: 14px;
  }
}

.about-text2 {
  color: $c-red;
  font-weight: bold;
  line-height: 1.78;
  @include pc {
    margin: 75px 0 0 -4px;
  }

  @include sp {
    margin: 40px 28px 0 28px;
    font-size: 11px;
  }

}

.about-text3 {
  color: $c-red;
  font-weight: bold;
  line-height: 1.85;

  @include pc {
    span.min {
      font-size: 14px;
    }
  }
  @include sp {
    margin: 40px 30px 0;
    font-size: 14px;
    span.min {
      font-size: 11px;
    }
  }

  span.dot {
    color: #EAECAB;
  }

}

// news --------------------------------------

.news {
  @include pc {
    margin: 80px 0 0;
  }

  @include sp {
    margin: 48px 0 0;

  }
}

.news-title {

  @include pc {
    @include img("./imgs/news-title@2x.png", 245px, 60px);
    margin: 85px auto 0;
  }

  @include sp {
    @include img("./imgs/news-title@2x.png", 146px, 33px);
    margin: 38px auto 0;
  }
}

.news-list {
  max-width: $content-inner-width;
  font-weight: bold;
  color: $c-red;
  line-height: 1.75;
  @include pc {
    margin: 55px auto;
  }

  @include sp {
    margin: 40px 30px;
  }
}


.news-item {
  position: relative;


  @include pc {
    padding: 26px 0;
    background: url("./imgs/dot-line@2x.png") repeat-x left 0 bottom 0px / contain;
  }

  @include sp {
    padding: 18px 0;
    background: url("./imgs/sp/dot-line@2x.png") repeat-x left 0 bottom 0px / contain;
  }

  &:first-child {
    padding-top: 0;
  }
}

.news-name {
  @include pc {
    font-size: 16px;
    padding: 0 120px 0 0;
    word-break: break-all;
  }

  @include sp {
    font-size: 14px;

  }
}

.news-date {
  color: $c-red;
  line-height: 1.75;
  @include pc {

    font-size: 16px;
  }

  @include sp {
    font-size: 14px;

  }
}


.news-more {
  position: absolute;
  right: 0;
  text-decoration: none;

  @include pc {
    font-size: 16px;
    bottom: 26px;
  }

  @include sp {
    font-size: 14px;
    bottom: 18px;
  }
}

// movie -------------------------------------
.movie {
  @include pc {
    margin: 120px auto 0;
  }

  @include sp {
    margin: 45px auto 0;
  }
}

.movie-inner {
  @include pc {
    margin: 0 auto;
    max-width: $content-inner-width;
  }

  @include sp {
    margin: 0 auto;
  }
}

.movie-title {
  margin: 0 auto;
  @include pc {
    margin: 85px auto 0;
    @include img("./imgs/movie-title@2x.png", 277px, 59px);
  }

  @include sp {
    margin: 38px auto 0;
    @include img("./imgs/movie-title.png", 193px, 31px);
  }
}

.movie-main {
  padding: 54.75% 0 0;
  position: relative;

  @include pc {
    margin: 48px 0 0;
  }

  @include sp {
    margin: 18px 30px 0;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

// to-report -------------------------------------

.to-report {
  display: block;
  text-decoration: none;
  transition: opacity 200ms;

  @include sp {
    margin: 0 30px;
  }
}


.to-report-title {
  @include pc {
    @include img("./imgs/report-banner@2x.png", 800px, 230px);
    margin: 130px auto 0;
    &:hover {
      opacity: 0.8;
    }
  }

  @include sp {
    margin: 70px auto 0;
    width: 100%;
    padding: 29.4% 0 0;
    background: url("./imgs/sp/report-banner@2x.png") no-repeat center / contain;
    text-indent: 110%;
    overflow: hidden;

  }
}

// gallery -----------------------------------
.gallery {
  @include pc {
    margin: 120px 0 0;
  }
  @include sp {
    margin: 50px 0 0;
  }
}

.gallery-title {
  margin: 0 auto;

  @include pc {
    @include img("./imgs/gallery-title@2x.png", 364px, 60px);
  }
  @include sp {
    @include img("./imgs/gallery-title.png", 292px, 31px);
  }
}

.gallery-list {
  display: grid;

  @include pc {
    width: 800px;
    margin: 60px auto 0;
    grid-template-columns: 250px 250px 250px;
    gap: 25px;

  }
  @include sp {
    margin: 22px 30px 0;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
  }
}

.gallery-item {
  background: #CECECE no-repeat center / cover;

  @include pc {
    width: 250px;
    height: 250px;
  }

  @include sp {
    padding: 100% 0 0;
  }
}

// map ---------------------------------------

.map {
  @include sp {
    margin: 0 30px;
  }
}

.map-title {
  @include pc {
    @include img("./imgs/map-title@2x.png", 296px, 60px);
    margin: 120px auto 0;
  }
  @include sp {
    @include img("./imgs/map-title.png", 198px, 30px);
    margin: 34px auto 0;
  }
}

.map-head {
  @include pc {
    display: flex;
    max-width: $content-inner-width;
    margin: 40px auto 0;
    justify-content: space-between;
  }

  @include sp {
    margin: 17px auto 0;
  }
}

.map-head-title {
  @include pc {
    @include img("./imgs/map-schedule-title@2x.png", 276px, 24px);
  }
  @include sp {
    @include img("./imgs/map-schedule-title.png", 161px, 14px);
    margin: 0 auto;
  }
}

.map-head-instagram {
  @include pc {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    color: $c-red;
    align-items: center;
    text-decoration: none;
  }

  &:after {
    content: "";
    @include img("./imgs/footer-instagram@2x.png", 22px, 22px);
    margin: 0 0 0 5px;
  }

  @include sp {
    display: none;
  }
}
.map-footer {
  margin: 10px  0;
}

.map-footer-instagram {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  color: $c-red;
  align-items: center;
  text-decoration: none;
  justify-content: center;

  &:after {
    content: "";
    @include img("./imgs/footer-instagram@2x.png", 22px, 22px);
    margin: 0 0 0 5px;
  }

  @include pc {
    display: none;
  }
}

.map-schedule {
  @include pc {
    @include img("./imgs/map-schedule-main@2x.png", 800px, 221px);
    margin: 12px auto 0;
  }
  @include sp {
    background: url("./imgs/sp/map-schedule-main@2x.png") center / cover;
    margin: 12px auto 0;
    width: 100%;
    padding: 41.71% 0 0;
  }
}

.map-main {
  @include pc {
    @include img("./imgs/map-main@2x.png", 799px, 458px);
    margin: 40px auto 0;
  }
  @include sp {
    background: url("./imgs/map-main.png") center / cover;
    width: 100%;
    padding: 57% 0 0;
    margin: 30px auto 0;
  }
}

.map-desc {
  color: $c-red;
  font-weight: bold;

  @include pc {
    font-size: 16px;
    max-width: 800px;
    margin: 10px auto 0;
  }
  @include sp {
    font-size: 14px;
    margin: 8px auto 0;
    text-align: center;

    span {
      display: block;
      opacity: 0;
      overflow: hidden;
      height: 1px;
    }
  }
}

// form -------------------------------------
.form {
  @include pc {
    margin: 105px 0 0;

  }

  @include sp {
    margin: 55px 0 0;

  }
}

.form-button {
  margin: 30px auto 0;
  @include pc {
    @include img("./imgs/top-button@2x.png", 440px, 80px);
    transition: opacity 200ms;

    &:hover {
      opacity: 0.7;
    }
  }

  @include sp {
    @include img("./imgs/top-button.png", 260px, 55px);
  }
}

.form-text {
  font-weight: bold;
  text-align: center;
  color: $c-red;
  @include pc {
    font-size: 21px;
    margin: 20px 0 0;

  }

  @include sp {
    font-size: 17px;
    margin: 18px 0 0;

  }
}

// support ----------------------------------
.support {
  @include pc {
    margin: 90px 0 0;
  }

  @include sp {
    margin: 35px 0 0;
  }
}

.support-title {
  font-weight: bold;
  text-align: center;
  color: $c-red;
  line-height: 1.8;

  @include pc {
    margin: 94px auto 0;
    max-width: $content-inner-width;
    font-size: 21px;

    .line {
      margin: 44px 0 0;
    }
  }

  @include sp {
    margin: 45px 25px 0;
    font-size: 11px;

    .line {
      margin: 26px 0 0;
    }
  }
}

.support-sub-title {
  font-weight: bold;
  text-align: center;
  color: $c-red;
  line-height: 1.8;

  @include pc {
    font-size: 25px;
    margin: 45px 0 0;

  }

  @include sp {
    margin: 25px 0 0;
    font-size: 17px;

  }
}

.support-list {
  display: flex;
  justify-content: center;

  @include pc {
    margin: 40px auto 0;
  }
  @include sp {
    margin: 20px 0 0;
  }
}

.support-item {
  display: block;
  text-decoration: none;
  @include pc {
    margin: 0 25px;
    transition: opacity 200ms;

    &:hover {
      opacity: 0.7;
    }

  }

  @include sp {
    margin: 0 0 0 20px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.support-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @include pc {
    width: 147px;
    height: 160px;

  }

  @include sp {
    width: 85px;
    height: 92px;

  }

  &.support1 {
    background: url("./imgs/support1@2x.png") no-repeat center / contain;
  }

  &.support2 {
    background: url("./imgs/support2@2x.png") no-repeat center / contain;
  }

  &.support3 {
    background: url("./imgs/support3@2x.png") no-repeat center / contain;
  }
}

.support-name {
  font-weight: bold;
  color: $c-red;
  margin: 10px auto 0;
  text-align: center;

  @include pc {
    font-size: 20px;
  }

  @include sp {
    font-size: 14px;
  }
}


// footer ------------------------------------

.footer {
  background: #DADE6A;
  @include pc {
    margin: 100px 0 0;
    padding: 0 0 32px;

  }

  @include sp {
    margin: 40px 0 0;
    padding: 0 0 15px;
  }
}

.footer-inner {
  position: relative;
  @include pc {
    padding: 80px 0 0;
    max-width: $content-width;
    margin: 0 auto;
  }

  @include sp {
    padding: 86px 0 0;
    margin: 0 30px;

  }
}

.footer-ribbon {
  @include pc {
    margin: 32px 0 0;
  }

  @include sp {
    margin: 16px 0 0;
  }
}

.footer-copyright {
  font-weight: bold;
  color: $c-red;
  text-align: center;

  @include pc {
    font-size: 13px;
  }

  @include sp {
    font-size: 10px;
  }
}

.footer-logo {
  position: absolute;
  left: 0;

  @include pc {
    @include img("./imgs/footer-logo@2x.png", 188px, 68px);
    top: 36px;
    transition: opacity 200ms;

    &:hover {
      opacity: 0.7;
    }
  }

  @include sp {
    @include img("./imgs/footer-logo.png", 123px, 44px);
    top: 27px;
  }
}

.footer-instagram {
  position: absolute;

  @include pc {
    top: 46px;
    right: 75px;
    @include img("./imgs/footer-instagram@2x.png", 45px, 45px);
    transition: opacity 200ms;

    &:hover {
      opacity: 0.7;
    }
  }

  @include sp {
    top: 38px;
    right: 35px;
    @include img("./imgs/footer-instagram.png", 23px, 23px);
  }
}

.footer-facebook {
  position: absolute;
  right: 0;
  @include pc {
    top: 46px;
    @include img("./imgs/footer-facebook@2x.png", 45px, 45px);
    transition: opacity 200ms;

    &:hover {
      opacity: 0.7;
    }
  }

  @include sp {
    top: 38px;
    @include img("./imgs/footer-facebook.png", 23px, 23px);
  }
}


/////////////////////////////////////////////////////////////////////////////////////////
// news, report

// header-----------------------------------------

.header {
  @include pc {
    padding: 25px 0 0;
  }

  @include sp {
    padding: 18px 0 0;
  }
}

.header-inner {
  display: flex;
  justify-content: space-between;

  @include pc {
    max-width: $content-width;
    margin: 30px auto 0;
  }

  @include sp {
    margin: 14px 30px 0;
  }
}

.header-logo {
  @include pc {
    @include img("imgs/header-logo@2x.png", 188px, 68px);
    &:hover {
      opacity: 0.7;
    }
    transition: opacity 200ms;
  }

  @include sp {
    @include img("imgs/header-logo@2x.png", 122px, 44px);
  }
}

.header-home {
  @include pc {
    @include img("imgs/header-home@2x.png", 70px, 70px);
    &:hover {
      opacity: 0.7;
    }
    transition: opacity 200ms;
  }

  @include sp {
    @include img("imgs/sp/header-home@2x.png", 45px, 45px);
  }
}

// report -------------------------------------------
.line {
  width: 100%;
  height: 3px;
  @include pc {
    background: url("./imgs/dot-line@2x.png") repeat-x left 0 center / contain;
  }

  @include sp {
    background: url("./imgs/sp/dot-line@2x.png") repeat-x left 0 center / contain;
  }
}

.day1 {
  @include pc {
    @include img("imgs/day1@2x.png", 90px, 35px);
  }

  @include sp {
    @include img("imgs/day1@2x.png", 67px, 26px);
  }
}

.day2 {
  @include pc {
    @include img("imgs/day2@2x.png", 90px, 35px);
  }

  @include sp {
    @include img("imgs/day2@2x.png", 67px, 26px);
  }
}

.report {
  @include pc {
    margin: 35px auto 0;
    max-width: $content-inner-width;
  }

  @include sp {
    margin: 35px 30px 0;
  }
}

// report-head ------

.report-title {
  margin: 0 auto;

  @include pc {
    @include img("imgs/report-title@2x.png", 381px, 72px);
  }

  @include sp {
    @include img("imgs/report-title.png", 181px, 31px);
  }
}

.report-head {
  @include pc {
    margin: 50px 0 0;
  }

  @include sp {
    margin: 30px 0 0;
  }
}

.report-head-text {
  color: $c-red;
  line-height: 1.75;
  @include pc {
    font-size: 16px;
    padding: 15px 0;
  }

  @include sp {
    font-size: 14px;
    padding: 20px 0;
  }
}

// report-overview
.report-overview {
  @include pc {
    margin: 70px 0 0;
  }

  @include sp {
    margin: 30px 0 0;
  }
}

.report-overview-title {
  @include pc {
    @include img("imgs/report-overview-title@2x.png", 170px, 22px);
  }

  @include sp {
    @include img("imgs/report-overview-title@2x.png", 110px, 14px);
  }
}

.report-overview-main {
  margin: 22px 0 0;
  @include pc {
    display: flex;
  }
}

.report-overview-day1 {
  @include pc {
    flex: 0 0 auto;
    width: 393px;
    margin: 0 30px 0 0;
  }

  @include sp {

  }
}

.report-overview-day2 {
  @include pc {
    flex: 0 0 auto;
    width: 285px;
  }

  @include sp {
    margin: 15px 0 0;
  }
}

.report-overview-text {
  line-height: 1.85;
  color: $c-red;
  font-weight: bold;

  @include pc {
    font-size: 16px;
    margin: 16px 0 0;
  }

  @include sp {
    font-size: 14px;
    margin: 9px 0 0;
  }

  span {
    color: #EAECAB;
  }
}


// report-topic -----------------
.report-topic {
  @include pc {
    margin: 70px 0 0;
  }

  @include sp {
    margin: 25px 0 0;
  }
}

.report-topic-day {
  @include pc {
    margin: 50px 0 0;
  }

  @include sp {
    margin: 30px 0 0;
  }
}

.report-topic-title {
  font-weight: bold;
  color: $c-red;
  @include pc {
    font-size: 21px;
    margin: 18px 0 0;
  }

  @include sp {
    font-size: 19px;
    margin: 11px 0 0;
  }
}

.block-text {
  color: $c-red;
  line-height: 1.75;

  @include pc {
    margin: 40px 0 0;
    font-size: 16px;
  }

  @include sp {
    margin: 24px 0 0;
    font-size: 14px;
  }
}

.block-image-text {

  @include pc {
    margin: 74px 0 0;
  }

  @include sp {
    margin: 26px 0 0;
  }


  img {
    width: 100%;
    display: block;
  }

  p {
    color: $c-red;
    line-height: 1.75;
    @include pc {
      font-size: 16px;
      margin: 26px 0 0;
    }

    @include sp {
      font-size: 14px;
      margin: 12px 0 0;
    }
  }

}

.block-image3-text {
  img {
    width: 100%;
    display: block;
  }

  p {
    color: $c-red;
    line-height: 1.75;
    @include pc {
      font-size: 16px;
      margin: 26px 0 0;
    }

    @include sp {
      font-size: 14px;
      margin: 14px 0 0;
    }
  }
}

.block-image3-list {
  @include pc {
    display: flex;
    justify-content: space-between;
  }
}

.block-image3-item {
  @include pc {
    flex: 0 0 auto;
    margin: 74px 0 0;
    width: calc(33.33% - 16px);
  }
  @include sp {
    margin: 15px 0 0;
  }
}

.block-image4-text {
  img {
    width: 100%;
    display: block;
  }

  p {
    color: $c-red;
    line-height: 1.75;
    @include pc {
      font-size: 16px;
      margin: 26px 0 0;
    }

    @include sp {
      font-size: 14px;
      margin: 14px 0 0;
    }
  }
}

.block-image4-list {
  @include pc {
    display: flex;
    justify-content: space-between;
  }
}

.block-image4-item {
  @include pc {
    flex: 0 0 auto;
    margin: 74px 0 0;
    width: calc(25% - 15px);
  }
  @include sp {
    margin: 15px 0 0;
  }
}

.block-col2-image-text {
  @include pc {
    display: flex;
    margin: 75px 0 0;
  }

  @include sp {
    margin: 36px 0 0;
  }

  img {
    width: 100%;
    display: block;
  }

  p {
    color: $c-red;
    line-height: 1.75;

    @include pc {
      font-size: 16px;
    }

    @include sp {
      font-size: 14px;
      margin: 10px 0 0;
    }
  }
}

.block-col2-image-text-left {

  @include pc {
    width: 400px;
    margin: 0 25px 0 0;
  }

  @include sp {

  }
}

.block-col2-image-text-right {
  @include pc {
    width: 375px;
  }

  @include sp {

  }
}

// shops -------------------------------------------------------

.report-shops {
  @include pc {
    margin: 80px 0 0;
  }

  @include sp {
    margin: 30px 0 0;
  }
}

.report-shops-title {
  font-size: 18px;
  font-weight: bold;
  color: $c-red;
  @include pc {
    margin: 55px 0 0;
  }

  @include sp {
    margin: 34px 0 0;
  }
}

.report-shops-list {
  margin: 26px 0 0;

  @include pc {
    display: grid;
    grid-template-columns: 250px 250px 250px;
    gap: 25px;
  }
}

// shop -----------------

.report-shop {
  flex: 0 0 auto;
  line-height: 1.75;
  color: $c-red;

  @include sp {
    margin: 36px 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

.report-shop-sub {
  font-size: 11px;
}


.report-shop-name {
  font-size: 14px;
  font-weight: bold;
}


.report-shop-info {
  font-size: 11px;
  margin: 11px 0 0;
}

.report-shop-desc {
  font-size: 12px;
  line-height: 1.65;
  margin: 16px 0 0;
}

.report-shop-images {
  margin: 15px 0 0;
  width: 100%;
}

.report-shop-image {
  width: 100%;
  display: block;
  margin: 10px 0 0;
}

.report-shop-sns {
  @include pc {
    margin: 10px 0 0;

    &[data-space-wide] {
      margin: 30px 0 0;
    }
  }

  @include sp {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.report-shop-texts {
  @include sp {
    position: relative;
  }
}

.report-shop-facebook {
  width: 30px;
  height: 30px;
  @include img("./imgs/shop-facebook@2x.png", 30px, 30px);
}

.report-shop-twitter {
  width: 30px;
  height: 30px;
  @include img("./imgs/shop-twitter@2x.png", 30px, 30px);
}

.report-shop-instagram {
  width: 30px;
  height: 30px;
  @include img("./imgs/shop-instagram@2x.png", 30px, 30px);
}

// news -------------------------
.news-detail-list {

  @include pc {
    max-width: $content-inner-width;
    margin: 0 auto;
    min-height: 300px;
  }

  @include sp {
    margin: 0 30px 90px;
    min-height: 300px;
  }

}

.news-detail-list-inner {
  opacity: 0;

  &[data-inited="true"] {
    opacity: 1;
  }
}

.news-detail {
  line-height: 1.75;
  color: $c-red;

  @include pc {
    margin: 46px 0 0;
  }

  @include sp {
    margin: 26px 0 0;
  }
}

.news-detail-date {
  @include pc {
    font-size: 21px;
  }

  @include sp {
    font-size: 14px;
  }
}

.news-detail-title {
  word-break: break-all;

  @include pc {
    font-size: 21px;
    margin: 12px 0 0;
  }

  @include sp {
    font-size: 14px;
    margin: 4px 0 0;
  }
}

.news-detail-body {
  font-weight: normal;
  word-break: break-all;

  @include pc {
    font-size: 16px;
    margin: 28px 0 70px;
  }

  @include sp {
    font-size: 14px;
    margin: 16px 0 26px;
  }

  img {
    width: 100%;
  }
}

.news-detail-nav {
  display: flex;
  justify-content: space-between;
  @include pc {
    font-size: 16px;
    margin: 40px 0 0;
  }
  @include sp {
    font-size: 14px;
    margin: 20px 0 0;
  }
}

.news-detail-prev,
.news-detail-next {
  color: $c-red;
  font-weight: bold;
  text-decoration: none;
}

